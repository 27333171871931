














import Vue, { PropType } from 'vue';
import icon from '@/assets/icon';
export default Vue.extend({
  data() {
    return {
      icon,
      steps: ['received', 'inTransit', 'delivered'] as const
    };
  },
  props: {
    status: {
      type: String as PropType<'received' | 'inTransit' | 'delivered'>,
      required: true,
    },
  },
});
