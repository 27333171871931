import Vue, { CreateElement, VNode } from 'vue';
import { mapGetters, mapMutations } from 'vuex';

import {
  getDaysInTransit,
  getOverviewStatus,
  trackingNumberValidator,
  validateTrackingNumber,
} from '@/utility/common';
import { fetchTrackingResult } from '@/views/MTracking/utility';

export default Vue.extend({
  computed: {
    ...mapGetters(['currentLanguage']),
    destination(): string {
      return this.trackingResponse?.data?.details?.destination || '-';
    },
    origin(): string {
      return this.trackingResponse?.data?.details?.origin || '-';
    },
    courier(): {
      name: string;
      logoUrl: string;
      phoneNumber: string;
      url: string;
    } {
      const carrier = this.trackingResponse?.data?.courier || {};

      return {
        name: carrier.name,
        logoUrl: carrier.logo_url,
        phoneNumber: carrier.phone_number,
        url: carrier.url,
      };
    },
    daysInTransit(): string {
      if (!this.trackingResponse.data?.events) {
        return '0';
      }
      const eventsLength = this.trackingResponse.data?.events.length - 1;
      //event is in descending order
      const firstDate = this.trackingResponse.data?.events[eventsLength].date;
      const isDeliveredFinished =
        this.trackingResponse.data?.events[0].type === 'delivered' ||
        this.trackingResponse.data?.events[0].type === 'failed' ||
        this.trackingResponse.data?.events[0].type === 'canceled';

      const today = new Date().toISOString().split('T')[0];
      const lastDate = isDeliveredFinished
        ? this.trackingResponse.data?.events[0].date
        : today;

      return getDaysInTransit(firstDate, lastDate);
    },
    trackingStatus(): 'received' | 'inTransit' | 'delivered' | 'exception' {
      return getOverviewStatus(this.trackingResponse.data?.events);
    },
  },
  data(): {
    //TODO: define trackingResponse type
    // eslint-disable-next-line
    trackingResponse: any;
    isLoading: boolean;
  } {
    return {
      trackingResponse: {},
      isLoading: false,
    };
  },
  props: {
    trackingInputValue: {
      type: String,
    },
  },
  methods: {
    ...mapMutations(['updateMessage']),

    //TODO: define the response type
    async fetchTracking(trackingInputValue: string) {
      this.isLoading = true;

      await fetchTrackingResult(trackingInputValue, this.currentLanguage.code)
        .then(response => {
          this.trackingResponse = response.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    handleButtonClick(event: Event) {
      event.preventDefault();

      if (!this.trackingInputValue) {
        return;
      }
      if (!this.$v.trackingInputValue.maxLength) {
        this.updateMessage({ message: this.$t('tracking.invalidLength') });

        return;
      }
      if (this.$v.trackingInputValue.$invalid) {
        this.updateMessage({ message: this.$t('tracking.invalidFormat') });
        return;
      }
      this.$router
        .push({
          name: 'Tracking',
          params: {
            trackingNumber: this.$props.trackingInputValue,
            locale: this.$route.params.locale,
          },
        })
        .catch(() => ({}));
    },
    handleCopyToClipboard() {
      navigator.clipboard
        .writeText(this.$route.params.trackingNumber)
        .then(() => {
          this.updateMessage({
            message: this.$t('tracking.copiedToClipboard'),
          });
        })
        .catch(() =>
          this.updateMessage({ message: this.$t('tracking.copiedFailed') })
        );
    },
  },
  async mounted() {
    const isValidTrackingNumber = validateTrackingNumber(
      this.$props.trackingInputValue
    );
    if (!isValidTrackingNumber) {
      this.$router
        .push({
          name: 'Landing',
        })
        .catch(() => ({}));
    }
    await this.fetchTracking(this.$route.params.trackingNumber);
  },
  render(h: CreateElement): VNode {
    return h(
      'div',
      {
        class: 'container py-0',
        style: 'max-width: 1600px; height: 100%;',
      },
      // eslint-disable-next-line
      this.$scopedSlots.default!({
        trackingResponse: this.$data.trackingResponse,
        handleButtonClick: this.handleButtonClick,
        isLoading: this.isLoading,
        handleCopyToClipboard: this.handleCopyToClipboard,
        destination: this.destination,
        origin: this.origin,
        courier: this.courier,
        daysInTransit: this.daysInTransit,
        trackingStatus: this.trackingStatus,
      })
    );
  },
  watch: {
    $route(to) {
      this.fetchTracking(to.params.trackingNumber);
    },
  },
  validations: {
    trackingInputValue: trackingNumberValidator,
  },
});
