import Vue from 'vue';
import Meta from 'vue-meta';
import VueRouter, { RouteConfig } from 'vue-router';
import MLanding from '../views/MLanding';
import MTracking from '../views/MTracking';

Vue.use(VueRouter);
Vue.use(Meta, { refreshOnceOnNavigation: true });

export const routes: Array<RouteConfig> = [
  {
    name: 'Landing',
    path: '/:locale?',
    component: MLanding,
  },
  {
    path: '/:locale?/tracking/:trackingNumber',
    name: 'Tracking',
    component: MTracking,
  },
  {
    path: '*',
    name: 'Default',
    component: MLanding,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  //scroll to the top on new page
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
