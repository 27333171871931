export default {
  title: 'Mint Tracking | 배송조회',
  keywords:
    '배송 조회, mint tracking, 사가와 글로벌, 야마토 글로벌, GSM, DHL e-commerce, DHL Express, UPS, 우체국 택배',
  description:
    '운송장 번호를 입력하고 실시간 배송정보를 손쉽게 한 곳에서 확인하세요.',
  'app.updateAvailable': '업데이트가 있습니다.',
  'app.update': '새로고침',
  'landing.title': '실시간 배송정보 조회',
  'landing.description':
    '아래 입력창에 운송장 번호를 입력하고 실시간 배송 정보를 확인하세요. 사가와 글로벌, 야마토 글로벌, GSM, DHL, 우체국 택배 등의 배송정보를 한 곳에서 조회할 수 있습니다.',
  'landing.trackingInputPlaceholder': '운송장 번호',
  'landing.trackingButton': '조회',
  'trackingStatus.received': '준비중',
  'trackingStatus.inTransit': '배송시작',
  'trackingStatus.delivered': '배송완료',
  'trackingOverview.daysInTransit': '이동 중 (총 {day}일)',
  'trackingOverview.deliveredIn': '배송완료 (총 {day}일)',
  'footer.copyright': 'Copyright © 2021',
  'footer.allRightsReserved': 'All rights reserved',
  'trackingAdditionalInfo.additionalInfo': '상세 정보',
  'trackingAdditionalInfo.trackingNumber': '배송 번호',
  'trackingAdditionalInfo.origin': '발송지',
  'trackingAdditionalInfo.destination': '도착지',
  'trackingCourierInfo.courierInformation': '운송사 정보',
  'trackingCourierInfo.courier': '운송사',
  'trackingCourierInfo.phone': '전화번호',
  'trackingCourierInfo.website': '사이트',
  'trackingCourierInfo.noImage': '이미지 없음',
  'tracking.detail': '상세보기',
  'tracking.notFound.title': '조회된 데이터가 없습니다.',
  'tracking.invalidLength': '잘못된 운송장 번호입니다.',
  'tracking.invalidFormat': '영어 대문자와 숫자를 입력해 주세요.',
  'tracking.copiedToClipboard': '클립보드에 복사되었습니다.',
  'tracking.copiedFailed': '클립보드 복사에 실패하였습니다.',
};
