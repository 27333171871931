import axios from 'axios';
export const fetchTrackingResult = async (
  trackingNumber: string,
  languageCode: string
) => {
  const isDev = process.env.NODE_ENV === 'development';
  const url = isDev
    ? 'https://test.mintshipping.com/'
    : 'https://mintshipping.com/';
  const params = `api/track/${trackingNumber}?language_code=${languageCode}`;
  const apiEndpoint = url + params;
  const token = isDev
    ? process.env.VUE_APP_TOKEN_DEV
    : process.env.VUE_APP_TOKEN_PROD;
  return await axios.get(
    apiEndpoint,
    // config
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
};
