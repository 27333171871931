














import Vue from 'vue';
import MTrackingDetail from './MTrackingDetail';

interface Style {
  container: {
    overflow: string;
    height: {
      height: string;
    };
  };
}

export default Vue.extend({
  name: 'MTrackingDetails',
  components: {
    MTrackingDetail,
  },
  computed: {
    style(): Style {
      const container = {
        overflow: `${this.isMobile ? '' : 'overflow-y-auto mt-6'}`,
        height: { height: this.isMobile ? '100%' : '355px' },
      };
      return { container };
    },
  },
  props: {
    trackingNodes: {
      required: true,
      type: Array as () => Array<{}>,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
});
