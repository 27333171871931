




















import Vue from 'vue';
import { mapMutations } from 'vuex';
import { mapGetters } from 'vuex';

import MLanguageList from './MLanguageList';
import icon from '@/assets/icon';
import img from '@/assets/img';

interface Data {
  icon: Record<string, string>;
  img: Record<string, string>;
}
export default Vue.extend({
  name: 'MAppbar',
  components: {
    MLanguageList,
  },
  computed: {
    ...mapGetters(['currentLanguage']),
    style(): { container: string } {
      const container = `d-flex align-center mx-auto justify-space-between ${
        this.isMobile ? 'pa-0' : ''
      }`;
      return {
        container,
      };
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data(): Data {
    return {
      icon,
      img,
    };
  },
  methods: {
    ...mapMutations(['updateLanguage']),
    handleLogoClick() {
      // https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
      this.$router
        .push({
          name: 'Landing',
          params: {
            locale: this.$route.params.locale,
          },
        })
        .catch(() => ({}));
    },
  },
});
