


























































import Vue from 'vue';
import { mapMutations } from 'vuex';
import { trackingNumberValidator } from '@/utility/common';
import img from '../../assets/img';
import icon from '../../assets/icon';

interface Style {
  container: { size: { height: string; maxWidth: string }; align: string };
  title: string;
  description: string;
  trackButton: {
    button: string;
    text: string;
  };
}

export default Vue.extend({
  name: 'MLanding',
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    style(): Style {
      const container = {
        // appbar height is 56px, footer height 56px
        size: { height: 'calc(100vh - 112px)', maxWidth: '1600px' },
        align: this.isMobile ? 'pa-0' : 'ml-0',
      };
      const description = `${
        this.isMobile ? 'text-body-1 my-5' : 'text-h5 my-15'
      }  font-weight-light ma-0`;
      const title = `${this.isMobile ? 'text-h4' : 'text-h3 '} ma-0
      `;
      const trackButton = {
        button: `indigo darken-4 white--text ${
          this.isMobile ? 'pa-2 ml-2' : 'ml-3'
        }`,
        text: `${
          this.isMobile ? 'mx-1 text-caption font-weight-regular' : 'mx-2'
        }`,
      };

      return {
        container,
        title,
        description,
        trackButton,
      };
    },
  },
  data() {
    return {
      trackingResponse: {},
      trackingNumber: '',
      img,
      icon,
      isInvalidNumber: false,
    };
  },
  methods: {
    ...mapMutations(['updateMessage']),
    handleButtonClick: function(event: Event) {
      event.preventDefault();
      if (!this.trackingNumber) {
        return;
      }
      if (!this.$v.trackingNumber.maxLength) {
        this.updateMessage({ message: this.$t('tracking.invalidLength') });
        return;
      }
      if (this.$v.trackingNumber.$invalid) {
        this.updateMessage({ message: this.$t('tracking.invalidFormat') });
        return;
      }

      this.$router.push({
        name: 'Tracking',
        params: {
          trackingNumber: this.trackingNumber,
          locale: this.$route.params.locale,
        },
      });
    },
  },
  validations: {
    trackingNumber: trackingNumberValidator,
  },
});
