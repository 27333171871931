






































import Vue, { PropType } from 'vue';
import icon from '@/assets/icon';
import img from '@/assets/img';

type Languages = Array<{
  lang: 'English' | 'Korean' | 'Japanese';
  code: 'en' | 'ko' | 'ja';
  src: string;
}>;
const languages: Languages = [
  { lang: 'English', code: 'en', src: img.unitedStates },
  { lang: 'Korean', code: 'ko', src: img.southKorea },
  { lang: 'Japanese', code: 'ja', src: img.japan },
];

type Data = {
  icon: Record<string, string>;
  img: Record<string, string>;
  languages: Languages;
};
export default Vue.extend({
  name: 'MLanguageList',
  computed: {
    style() {
      const highlighted = 'v-list-item--active';
      return {
        highlighted,
      };
    },
  },
  data(): Data {
    return {
      icon,
      img,
      languages,
    };
  },
  methods: {
    updateLanguage({
      language,
      code,
    }: {
      language: 'English' | 'Korean' | 'Japanese';
      code: 'en' | 'ko' | 'ja';
    }) {
      this.handleLanguageChange({
        language,
        code,
        callback: () => {
          //If the app language is about to set to English, don't include locale code in the URL
          this.$router
            .push({
              name: this.$router.currentRoute.name || 'Landing',
              ...(code !== 'en' && { params: { locale: code } }),
            })
            .catch(() => ({}));
        },
      });
    },
  },
  props: {
    currentLanguage: {
      type: String as PropType<'English' | 'Korean' | 'Japanese'>,
      required: true,
    },
    handleLanguageChange: {
      type: Function as PropType<
        ({
          language,
          code,
          callback,
        }: {
          language: 'English' | 'Korean' | 'Japanese';
          code: 'en' | 'ko' | 'ja';
          callback: Function;
        }) => void
      >,
    },
  },
});
