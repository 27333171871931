


































import Vue from 'vue';

import icon from '@/assets/icon';
export default Vue.extend({
  computed: {
    lineHeight(): string {
      return 'pt-0 pb-1';
    },
  },
  data() {
    return { icon };
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    trackingNumber: {
      type: String,
      required: true,
    },

    destination: {
      type: String,
      default: '-',
    },
    origin: {
      type: String,
      default: '-',
    },
    handleCopyToClipboard: {
      type: Function,
    },
  },
});
