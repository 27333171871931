export default {
  title: 'Mint Tracking | Track Parcel Online',
  keywords:
    'parcel tracking, mint tracking, sagawa global, yamato global, GSM, DHL e-commerce, DHL Express, UPS, Korea Post',
  description:
    'Track your cross border e-commerce parcels and returns with quick and easy parcel tracking.',
  'app.updateAvailable': 'An update is available',
  'app.update': 'update',
  'landing.title': 'Track your parcel',
  'landing.description':
    'Please enter your tracking number in the box below to view your shipping details. We support Sagawa Global, Yamato Global, GSM, DHL, Korea Post, and many other carriers.',
  'landing.trackingInputPlaceholder': 'Tracking number',
  'landing.trackingButton': 'track',
  'trackingStatus.received': 'Received',
  'trackingStatus.inTransit': 'In Transit',
  'trackingStatus.delivered': 'Delivered',
  'trackingOverview.daysInTransit': 'In transit for {day} days',
  'trackingOverview.deliveredIn': 'Delivered in {day} days',
  'footer.copyright': 'Copyright © 2021',
  'footer.allRightsReserved': 'All rights reserved',
  'trackingAdditionalInfo.additionalInfo': 'Additional Information',
  'trackingAdditionalInfo.trackingNumber': 'Tracking Number',
  'trackingAdditionalInfo.origin': 'Origin',
  'trackingAdditionalInfo.destination': 'Destination',
  'trackingCourierInfo.courierInformation': 'Courier Information',
  'trackingCourierInfo.courier': 'Courier',
  'trackingCourierInfo.phone': 'Phone',
  'trackingCourierInfo.website': 'Website',
  'trackingCourierInfo.noImage': 'No Image',
  'tracking.detail': 'Detail',
  'tracking.notFound.title': 'Result not found',
  'tracking.invalidLength': 'Tracking number length is invalid.',
  'tracking.invalidFormat':
    'Tracking number must contain only capital letters and digits.',
  'tracking.copiedToClipboard': 'Copied to clipboard.',
  'tracking.copiedFailed': 'Copy to clipboard failed.',
};
