















import Vue, { PropType } from 'vue';
import { TrackingNode } from '@/types/common';

export default Vue.extend({
  props: {
    trackingNode: {
      required: true,
      type: Object as PropType<TrackingNode>,
    },
  },
});
