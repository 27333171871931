import southKorea from './southKorea.svg';
import unitedStates from './unitedStates.svg';
import japan from './japan.svg';
import logo from './logo-160-white.png';
import landingImage from './minttracking_landing.svg';
import notFound from './notfound.svg';
import colouredLogo from './logo-200.png';
import iconImage from './iconImage.png';

export default {
  southKorea,
  unitedStates,
  japan,
  logo,
  landingImage,
  notFound,
  colouredLogo,
  iconImage,
};
