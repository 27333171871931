import Vue from 'vue';
import MApp from './MApp.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import Vuelidate from 'vuelidate';
import VueGtag from 'vue-gtag';

Vue.use(Vuelidate);
Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: {
      id:
        process.env.NODE_ENV !== 'production'
          ? process.env.VUE_APP_GA_DEV
          : process.env.VUE_APP_GA_PROD,
    },
  },
  router
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(MApp),
}).$mount('#app');
