export default {
  title: 'Mint Tracking | 貨物追跡',
  keywords:
    'parcel tracking, mint tracking, 佐川グローバル, ヤマトグローバル, GSM, DHL e-commerce, DHL Express, UPS, 韓国郵便',
  description: '越境ECの荷物を追跡し、簡単な荷物追跡ができます。',
  'app.updateAvailable': 'アップデートがあります',
  'app.update': '更新する',
  'landing.title': '貨物追跡',
  'landing.description':
    '追跡番号を入力してください。佐川グローバル、ヤマトグローバル、GSM, DHL e-commerce、DHLエクスプレス、UPS、韓国郵便の貨物追跡が可能です。',
  'landing.trackingInputPlaceholder': 'トラッキング番号',
  'landing.trackingButton': '追跡',
  'trackingStatus.received': '受取完了',
  'trackingStatus.inTransit': '配送中',
  'trackingStatus.delivered': '配達完了',
  'trackingOverview.daysInTransit': '発送から{day}日',
  'trackingOverview.deliveredIn': '{day}日で配達',
  'footer.copyright': 'Copyright © 2021',
  'footer.allRightsReserved': 'All rights reserved',
  'trackingAdditionalInfo.additionalInfo': '付属情報',
  'trackingAdditionalInfo.trackingNumber': '追跡コード',
  'trackingAdditionalInfo.origin': '発地国',
  'trackingAdditionalInfo.destination': '到着地',
  'trackingCourierInfo.courierInformation': '配送会社の連絡先情報',
  'trackingCourierInfo.courier': '配送会社',
  'trackingCourierInfo.phone': '電話番号',
  'trackingCourierInfo.website': 'URL',
  'trackingCourierInfo.noImage': 'イメージがありません',
  'tracking.detail': 'すべての出荷最新情報',
  'tracking.notFound.title': '結果はありません。',
  'tracking.invalidLength': '送り状番号の長さが有効ではありません。',
  'tracking.invalidFormat': '送り状番号は文字と数字でのみ入力可能です。',
  'tracking.copiedToClipboard': 'クリップボードにコピーしました。',
  'tracking.copiedFailed': 'クリップボードにコピーできませんでした。',
};
