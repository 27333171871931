import { required, alphaNum, maxLength } from 'vuelidate/lib/validators';
import { OverviewStatus, TrackingNode } from '@/types/common';

export const validateTrackingNumber = (trackingNumber: string) => {
  return trackingNumber.match(/^[a-zA-Z0-9]*$/) && trackingNumber.length <= 50;
};

export const trackingNumberValidator = {
  required,
  alphaNum,
  maxLength: maxLength(40),
};

/*
  https://stackoverflow.com/questions/2627473/how-to-calculate-the-number-of-days-between-two-dates
  https://stackoverflow.com/a/49425185/11065046
  date is 'yyyy-mm-dd' format
 */
export const getDaysInTransit = (
  firstDate: string,
  lastDate: string
): string => {
  if (!firstDate || !lastDate) {
    return '0';
  }
  const oneday = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  const first: Date = new Date(firstDate);
  const last: Date = new Date(lastDate);

  return Math.round(Math.abs((+last - +first) / oneday)).toString();
};

export const getOverviewStatus = (
  trackingNodes: Array<TrackingNode>
): OverviewStatus => {
  const statusMapper = {
    receiving: 'received',
    collecting: 'received',
    processing: 'received',
    preperation: 'received',
    departed: 'inTransit',
    in_transit: 'inTransit',
    in_transit_to_destination_country: 'inTransit',
    arrived: 'inTransit',
    transferred: 'inTransit',
    arrived_in_country_destination: 'inTransit',
    customs_clearance: 'inTransit',
    customs_cleared: 'inTransit',
    out_for_delivery: 'inTransit',
    available_for_pickup: 'inTransit',
    delivery_attempt_failed: 'inTransit',
    exception_resolved: 'inTransit',
    picked_up: 'delivered',
    failed: 'delivered',
    delivered: 'delivered',
    canceled: 'delivered',
    on_hold: 'exception',
    exception: 'exception',
  };

  if (!trackingNodes) {
    return 'received';
  }

  for (let i = 0; i < trackingNodes.length; i++) {
    const eventNode = trackingNodes[i];
    if (eventNode.type && eventNode.type !== 'event') {
      return statusMapper[eventNode.type] as OverviewStatus;
    }
  }
  //when there is only one event type trackingNode/ multiple trackingNodes but all of them are Event type
  return trackingNodes.length === 1 ? 'received' : 'inTransit';
};
