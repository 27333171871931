

































































































































































































import Vue from 'vue';
import icon from '../../assets/icon';
import img from '../../assets/img';

// Components
import MTrackingRenderless from './MTrackingRenderless';
import MTrackingDetails from './MTrackingDetails';
import MTrackingOverview from './MTrackingOverview';
import MTrackingAdditionalInfo from './MTrackingAdditionalInfo';
import MTrackingCourierInfo from './MTrackingCourierInfo';

type Style = {
  container: number;
  leftCard: string;
  rightCard: string;
  rightCardSkeleton: string;
  additionalInfoSkeleton: string;
  courierInfoSkeleton: string;
  divider: { topLeftSectionHeight: string; topRightSectionHeight: string };
  overviewSpacing: string;
  inputHeight: string;
};
export default Vue.extend({
  components: {
    MTrackingDetails,
    MTrackingOverview,
    MTrackingRenderless,
    MTrackingAdditionalInfo,
    MTrackingCourierInfo,
  },
  data() {
    return {
      icon,
      trackingInputValue: '',
      isDetailOpen: false,
      img,
      attrs: {
        class: 'pa-0',
      },
      topSectionHeight: '40%',
      topLeftSectionOffset: '70px',
    };
  },
  computed: {
    style(): Style {
      const container = this.isMobile ? 12 : 6;
      const leftCard = this.isMobile ? 'pa-2' : 'py-8 px-12';
      const rightCard = this.isMobile ? 'pa-2' : 'py-8 px-12';
      const rightCardSkeleton = `paragraph, article, text, sentences, sentences, article, sentences, sentences, paragraph`;
      const additionalInfoSkeleton = 'paragraph, paragraph';
      const courierInfoSkeleton = 'paragraph, image';
      const divider = {
        topLeftSectionHeight: `height: calc(${this.topSectionHeight} - ${this.topLeftSectionOffset})`,
        topRightSectionHeight: `height: ${this.topSectionHeight}`,
      };
      const overviewSpacing = this.isMobile
        ? 'mt-5'
        : `mt-8 ${divider.topLeftSectionHeight}`;
      const inputHeight = this.isMobile ? '45px' : '50px';
      return {
        container,
        leftCard,
        rightCard,
        rightCardSkeleton,
        additionalInfoSkeleton,
        courierInfoSkeleton,
        divider,
        overviewSpacing,
        inputHeight,
      };
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    handleOpenDetail() {
      this.isDetailOpen = !this.isDetailOpen;
    },
  },
});
