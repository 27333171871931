













































import Vue from 'vue';

export default Vue.extend({
  computed: {
    lineHeight(): string {
      return 'py-0 pb-1';
    },
    style() {
      const imageNotFound = {
        width: '200px',
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'grey',
      };
      return {
        imageNotFound,
      };
    },
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    imgUrl: {
      type: String,
    },
    courier: {
      type: String,
      default: '-',
    },
    phone: {
      type: String,
      default: '-',
    },
    website: {
      type: String,
      default: '-',
    },
  },
});
