













import Vue from 'vue';
import MAppbar from './components/MAppbar';
import MFooter from './components/MFooter';
import MSnackbar from './components/MSnackbar';
import PwaRenderless from './PwaRenderless';

import { MetaInfo } from 'vue-meta';
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

//types
type SupportedLocales = 'en' | 'ko' | 'ja';

const languages: {
  en: { language: 'English'; code: 'en' };
  ko: { language: 'Korean'; code: 'ko' };
  ja: { language: 'Japanese'; code: 'ja' };
} = {
  en: {
    language: 'English',
    code: 'en',
  },
  ko: {
    language: 'Korean',
    code: 'ko',
  },
  ja: {
    language: 'Japanese',
    code: 'ja',
  },
};
export default Vue.extend({
  name: 'App',
  components: { MAppbar, MFooter, MSnackbar, PwaRenderless },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('title') as string,
      meta: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://www.minttracking.com' },
        {
          property: 'og:title',
          content: this.$t('title') as string,
        },
        {
          property: 'og:description',
          content: this.$t('description') as string,
        },
        {
          property: 'og:image',
          content: 'https://minttracking.com/img/iconImage.1a5f1330.png',
        },
        {
          property: 'og:site_name',
          content: this.$t('title') as string,
        },
        { property: 'robots', content: 'index,follow' },
        { name: 'description', content: this.$t('description') as string },
        { name: 'keywords', content: this.$t('keywords') as string },
      ],
    };
  },
  computed: {
    ...mapGetters(['snackbar']),
  },
  methods: {
    ...mapMutations(['updateLanguage']),
    getLocale() {
      const supportedLocales = ['en', 'ko', 'ja'];
      const specifiedLocale = (supportedLocales.includes(
        this.$route.params.locale
      ) && this.$route.params.locale) as SupportedLocales;

      const browserLocale = (navigator.language.indexOf('-') > -1
        ? navigator.language.split('-')[0]
        : navigator.language) as SupportedLocales;

      const isBrowserLocaleSupported = supportedLocales.includes(browserLocale);
      const locale =
        specifiedLocale || (isBrowserLocaleSupported && browserLocale) || 'en';

      //If locale is specified in the URL, use the specified locale.
      this.updateLanguage({
        ...languages[locale],
        callback: () =>
          this.$router
            .push({
              name: this.$route.name as string,
              ...(locale !== 'en' && { params: { locale } }),
            })
            .catch(() => ({})),
      });
    },
  },
  beforeMount() {
    this.getLocale();
  },
});
