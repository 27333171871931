import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '@/i18n';

Vue.use(Vuex);

type State = {
  language: {
    lang: 'English' | 'Korean' | 'Japanese';
    code: 'en' | 'ko' | 'ja';
  };
  snackbar: {
    isVisible: boolean;
    message: string;
    timeout: number;
    handleButtonClick?: Function;
    buttonMessage?: string;
  };
};

const state: State = {
  language: {
    lang: 'English',
    code: 'en',
  },
  snackbar: {
    isVisible: false,
    message: '',
    timeout: 2500,
    handleButtonClick: undefined,
    buttonMessage: undefined,
  },
};

const getters = {
  currentLanguage: (state: State) => state.language,
  snackbar: (state: State) => state.snackbar,
};

const mutations = {
  updateLanguage(
    state: State,
    payload: {
      language: 'English' | 'Korean' | 'Japanese';
      code: 'en' | 'ko' | 'ja';
      callback?: Function;
    }
  ): void {
    state.language.lang = payload.language;
    state.language.code = payload.code;
    i18n.locale = payload.code;

    payload.callback && payload.callback();
  },

  updateMessage(
    state: State,
    payload: {
      message: string;
      timeout: number;
      handleButtonClick?: Function;
      buttonMessage?: string;
    }
  ) {
    const {
      message = '',
      timeout = 2500,
      handleButtonClick = undefined,
      buttonMessage = undefined,
    } = payload;
    state.snackbar.isVisible = true;
    state.snackbar.message = message;
    state.snackbar.timeout = timeout;
    state.snackbar.handleButtonClick = handleButtonClick;
    state.snackbar.buttonMessage = buttonMessage;
  },
};

export const store = {
  state,
  getters,
  mutations,
  actions: {},
};

export default new Vuex.Store(store);
