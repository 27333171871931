import Vue, { CreateElement, VNode } from 'vue';
import { mapMutations } from 'vuex';

export default Vue.extend({
  data(): {
    refreshing: boolean;
    registration?: null | { waiting?: { postMessage: Function } };
    updateExists: boolean;
  } {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  created() {
    document.addEventListener(
      'swUpdated',
      this.updateAvailable as EventListener,
      {
        once: true,
      }
    );
    // Jest test environment doesn't have navigator.serviceWorker. Added optinal chaining in order to prevent runtime error
    navigator.serviceWorker?.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  methods: {
    ...mapMutations(['updateMessage']),
    updateAvailable(event: CustomEvent) {
      this.registration = event.detail;
      this.updateExists = true;
      this.updateMessage({
        message: this.$t('app.updateAvailable'),
        timeout: -1,
        handleButtonClick: this.refreshApp,
        buttonMessage: this.$t('app.update'),
      });
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW

      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
  },
  render(h: CreateElement): VNode {
    return h(
      'div',
      {},
      // eslint-disable-next-line
      this.$scopedSlots.default!({

      })
    );
  },
});
