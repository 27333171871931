









































type Style = {
  trackingNodeSpacing: string;
  trackingNodeHeight: '' | { height: string };
};
import Vue, { PropType } from 'vue';
import icon from '@/assets/icon';

import MTrackingStatus from './MTrackingStatus';
import MTrackingNode from './MTrackingNode';

export default Vue.extend({
  components: {
    MTrackingNode,
    MTrackingStatus,
  },
  computed: {
    style(): Style {
      const trackingNodeSpacing = this.isMobile ? 'mt-8' : 'align-center';
      const trackingNodeHeight = this.isMobile ? '' : { height: '150px' };
      return {
        trackingNodeSpacing,
        trackingNodeHeight,
      };
    },
  },
  data() {
    return {
      icon,
    };
  },
  props: {
    isMobile: {
      type: Boolean,
    },
    trackingNumber: {
      required: true,
      type: String,
    },
    trackingResponse: {
      required: true,
      type: Object,
    },
    handleCopyToClipboard: {
      type: Function,
    },
    daysInTransit: {
      type: String,
      default: '0',
    },
    trackingStatus: {
      type: String as PropType<'received' | 'inTransit' | 'delivered'>,
    },
  },
});
