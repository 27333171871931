























import Vue from 'vue';
import img from '@/assets/img';

export default Vue.extend({
  data() {
    return {
      img,
    };
  },
  computed: {
    isMobile() {
      return !this.$vuetify.breakpoint.smAndUp;
    },
    style(): {
      container: string;
      innerWrapper: string;
      contents: string;
    } {
      const container = this.isMobile ? 'py-0' : '';
      const innerWrapper = this.isMobile ? 'py-2' : '';
      const contents = `text-caption d-sm-flex ${this.isMobile ? '' : 'px-3'}`;
      return { container, innerWrapper, contents };
    },
  },
});
