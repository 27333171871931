import {
  mdiContentCopy,
  mdiChevronRight,
  mdiWeb,
  mdiBinoculars,
  mdiMenuRight,
} from '@mdi/js';

export default {
  mdiContentCopy,
  mdiChevronRight,
  mdiMenuRight,
  mdiWeb,
  mdiBinoculars,
};
